import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, useMediaQuery } from '@mui/material';
import Navbar_public from './Navbar_public';
import Memorial_viewer_public from './Memorial_viewer_public';
import About_card_public from './About_card_public';
import Messages_public from './Messages_public';
import Media_Player_public from './Media_Player_public';
import Voice_player_public from './Voice_player_public';
import Audio_Player_public from './Audio_player_public';
import ShareButton_public from './ShareButton_public';
import Rose from './Rose';
import Rose_stream from './Rose_stream';
import Donation_message from './Donation_message'; // Import the Donation_message component
import Donate_button from './Donate_button';

const Memorial_public = () => {
    const { memorialId } = useParams();
    const [showDonationMessage, setShowDonationMessage] = useState(false); // State to control visibility of Donation_message

    // Using useMediaQuery to detect if the screen width is below 600px (for mobile devices)
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <Navbar_public />
            <div style={{ backgroundImage: `url('/images/background3.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3, width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>

                            {/* Conditional rendering based on screen size */}
                            <Grid item xs={12} sm={9}>
                                <Memorial_viewer_public memorialId={memorialId} />
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <About_card_public memorialId={memorialId} />
                                    </Box> <br />
                                    
                                    {/* Mobile-specific order */}
                                    {isMobile ? (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Media_Player_public memorialId={memorialId} />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Voice_player_public memorialId={memorialId} />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Audio_Player_public memorialId={memorialId} />
                                            </Box>
                                            <br />
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Messages_public memorialId={memorialId} />
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Messages_public memorialId={memorialId} />
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>
                                    
                                    {/* Desktop-only media players */}
                                    {!isMobile && (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Media_Player_public memorialId={memorialId} />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Voice_player_public memorialId={memorialId} />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Audio_Player_public memorialId={memorialId} />
                                            </Box>
                                        </>
                                    )}
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <ShareButton_public memorialId={memorialId} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Donate_button handler={setShowDonationMessage} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
            {/* Conditionally render the Donation_message component based on state */}
            {showDonationMessage && <Donation_message />}
        </div>
    );
}

export default Memorial_public;

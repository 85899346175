import React, { useState } from 'react';
import { Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from './ShareModal';
import ShareButton from './ShareButton';

const ShareButton_public = ({ memorialId }) => {
    const [openModal, setOpenModal] = useState(false);
    const [currentMemorial, setCurrentMemorial] = useState(null);
    const baseUrl = window.location.origin; // Use current origin as base URL

    const handleOpenModal = () => {
        setCurrentMemorial(memorialId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setCurrentMemorial(null);
        setOpenModal(false);
    };

    const shareUrl = currentMemorial ? `${baseUrl}/View_memorial/${currentMemorial}` : '';

    return (
        <>
            <Button
                       variant="contained"
                       onClick={handleOpenModal}
                       endIcon={<ShareIcon style={{ color: 'black' }} />} // Black icon
                       style={{
                           backgroundColor: 'white', // White button
                           color: 'black', // Black text
                           width: '50%' // 50% width of the parent component
                       }}
            >
                Share Memorial
            </Button>
            <ShareModal
                open={openModal}
                handleClose={handleCloseModal}
                shareUrl={shareUrl}
            />
        </>
    );
};

export default ShareButton_public;

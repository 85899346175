import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Cookies from 'js-cookie';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';

// Supabase credentials
const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const Rose = () => {
    const [buttonLabel, setButtonLabel] = useState('Add A Rose');
    const [isDisabled, setIsDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const RoseIcon = '/images/rose.png'; // Path to rose icon image

    useEffect(() => {
        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (memorialId && userId) {
            const fetchMemorialData = async () => {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('roses')
                    .eq('memorial_Id', memorialId)
                    .single();

                if (error) {
                    console.error('Error fetching memorial data:', error);
                    return;
                }

                if (data && data.roses) {
                    const roses = data.roses;
                    const userRose = roses.find((rose) => rose.userId === userId);

                    if (userRose) {
                        setButtonLabel('Rose Added');
                        setIsDisabled(true);
                    }
                }
            };

            fetchMemorialData();
        }
    }, []);

    const handleAddRose = async () => {
        const memorialId = Cookies.get('memorialId');
        const userId = Cookies.get('userId');

        if (!userId) {
            setOpen(true); // Open the modal if no userId cookie is found
            return;
        }

        const { data: userData, error: userError } = await supabase
            .from('Users')
            .select('name, surname, profile_picture')
            .eq('id', userId)
            .single();

        if (userError) {
            console.error('Error fetching user data:', userError);
            return;
        }

        const { name, surname, profile_picture } = userData;

        const newRose = {
            userId,
            name,
            surname,
            profile_picture,
        };

        const { data, error } = await supabase
            .from('memorials')
            .select('roses')
            .eq('memorial_Id', memorialId)
            .single();

        if (error) {
            console.error('Error fetching memorial data:', error);
            return;
        }

        let updatedRoses = data.roses ? [...data.roses, newRose] : [newRose];

        const { error: updateError } = await supabase
            .from('memorials')
            .update({ roses: updatedRoses })
            .eq('memorial_Id', memorialId);

        if (updateError) {
            console.error('Error updating roses:', updateError);
        } else {
            setButtonLabel('Rose Added');
            setIsDisabled(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={handleAddRose}
                disabled={isDisabled}
                style={{ backgroundColor: 'white', color: 'black', width: '50%' }} // White button with black text and 60% width
                endIcon={<img src={RoseIcon} alt="Rose Icon" style={{ width: '24px', height: '24px' }} />} // Icon next to the button label
            >
                {buttonLabel}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Authentication Required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You must be logged in to interact with the memorial. Please log in or register.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate('/authentication')} color="primary">
                        Login/Register
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Rose;

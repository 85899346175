import React, { useState } from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import Navbar from './Navbar';
import Memorial_viewer from './Memorial_viewer';
import About_card from './About_card';
import Messages from './Messages';
import Media_Player from './Media_player';
import Voice_player from './Voice_player';
import Audio_Player from './Audio_player';
import ShareButton from './ShareButton';
import Rose from './Rose';
import Rose_stream from './Rose_stream';
import Donate_button from './Donate_button';
import Donation_message from './Donation_message';

const Memorial = () => {
    const [showDonationMessage, setShowDonationMessage] = useState(false);
    
    // Detect if the screen width is less than 600px (mobile devices)
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background3.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3, width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>

                            {/* Conditional rendering based on screen size */}
                            <Grid item xs={12} sm={9}>
                                <Memorial_viewer />
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <About_card />
                                    </Box>
                                    <br />
                                    
                                    {/* Mobile-specific order */}
                                    {isMobile ? (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Media_Player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Voice_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Audio_Player />
                                            </Box>
                                            <br />
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Messages />
                                            </Box>
                                        </>
                                    ) : (
                                        <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                            <Messages />
                                        </Box>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>
                                    
                                    {/* Desktop-only media players */}
                                    {!isMobile && (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Media_Player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Voice_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Audio_Player />
                                            </Box>
                                        </>
                                    )}
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <ShareButton />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Donate_button handler={setShowDonationMessage} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
            {/* Conditionally render the Donation_message component based on state */}
            {showDonationMessage && <Donation_message />}
        </div>
    );
}

export default Memorial;

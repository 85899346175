import React, { useState } from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import Navbar from './Navbar';
import Edit_memorial_viewer from './Edit_memorial_viewer';
import About_card_editable from './About_card_editable';
import Messages from './Messages';
import Edit_media_player from './Edit_media_player';
import Edit_voice_player from './Edit_voice_player';
import Edit_audio_Player from './Edit_audio_player';
import ShareButton from './ShareButton';
import Rose from './Rose';
import Rose_stream from './Rose_stream';
import Donate_button from './Donate_button';
import Donation_message from './Donation_message';

const Edit_memorial2 = () => {
    const [showDonationMessage, setShowDonationMessage] = useState(false); // State to control visibility of Donation_message

    // Using useMediaQuery to detect if the screen width is below 600px (for mobile devices)
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background6.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3, width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>
                            <Grid item xs={12} sm={9}>
                                <Edit_memorial_viewer />
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <About_card_editable />
                                    </Box>
                                    <br />

                                    {/* Mobile-specific order */}
                                    {isMobile ? (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_media_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_voice_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_audio_Player />
                                            </Box>
                                            <br />
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Messages />
                                            </Box>
                                        </>
                                    ) : (
                                        <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                            <Messages />
                                        </Box>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>

                                    {/* Desktop-only media players */}
                                    {!isMobile && (
                                        <>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_media_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_voice_player />
                                            </Box>
                                            <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                                <Edit_audio_Player />
                                            </Box>
                                        </>
                                    )}
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <ShareButton />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Donate_button handler={setShowDonationMessage} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto', maxWidth: '100vw' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
            {/* Conditionally render the Donation_message component based on state */}
            {showDonationMessage && <Donation_message />}
        </div>
    );
}

export default Edit_memorial2;

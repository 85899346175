import React, { useState } from 'react';
import { Button } from '@mui/material';

const Donate_button = ({ handler }) => {
  const [showText, setShowText] = useState(true); // State to control text visibility

  const handleClick = () => {
    handler(true);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      endIcon={<img src="/images/donate.png" alt="Donate" width="24px" height="24px" /> }
      onClick={handleClick}
      onMouseEnter={() => setShowText(false)} // Hide text on hover
      onMouseLeave={() => setShowText(true)} // Show text when not hovering
      style={{
        backgroundColor: 'white', // White button
        color: 'black', // Black text
        width: '50%' // 50% width of the parent component
    }}
    >
      {/* Show text only when not hovering */}
      {showText ? (
        <>Leave an offering</>
      ) : (
        <></>
      )}
    </Button>
  );
};

export default Donate_button;
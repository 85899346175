import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // To capture the memorial ID from the route
import { createClient } from "@supabase/supabase-js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Navbar_public from "./Navbar_public";
import './ViewMassMemorial.css'; // Custom CSS file for styling

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


function ViewMassMemorial() {
    const { id } = useParams(); // Get memorial ID from the route
    const [memorialData, setMemorialData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMemorial = async () => {
            const { data, error } = await supabase
                .from("mass_memorials")
                .select("title, description, deceased, cover_image") // Fetch cover_image here
                .eq("id", id)
                .single();

            if (error) {
                console.error("Error fetching memorial data:", error);
            } else {
                setMemorialData(data);
            }
            setLoading(false);
        };

        if (id) {
            fetchMemorial();
        }
    }, [id]);

    if (loading) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                <CircularProgress />
            </Grid>
        );
    }

    if (!memorialData) {
        return (
            <Grid container justifyContent="center" style={{ marginTop: "2rem" }}>
                <Typography variant="h5" color="error">
                    Memorial not found.
                </Typography>
            </Grid>
        );
    }

    const { title, description, deceased, cover_image } = memorialData;

    return (
        <div>
            <Navbar_public />
            <div style={{ backgroundImage: `url('/images/background3.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* Separate container for the title */}
                <Box sx={{
                    width: { xs: '90%', sm: '85%' },
                    maxWidth: '80vw',
                    margin: '50px auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '10px',
                    //backgroundImage: `url(${cover_image})`, // Use the cover image here
                    backgroundSize: 'cover', // Ensure the background covers the area
                    backgroundPosition: 'center', // Center the background image
                }}>
                    <Grid container spacing={3} style={{ padding: "2rem", textAlign: "center" }}>
                        <Grid item xs={12}>
                            <Card>
                                <div style={{ padding: "2rem" }}>
                                    <Typography variant="h3" className="header-engraved" style={{ color: "#000" }}>
                                        {title}
                                    </Typography>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                {/* Main container for the deceased data */}
                <Box sx={{
                    width: { xs: '90%', sm: '85%' },
                    maxWidth: '80vw',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '10px'
                }}>
                    <Grid container spacing={3} style={{ padding: "2rem" }}>
                        <Grid item xs={12}>
                            <Card>
                                <TableContainer component={Paper} className="granite-background" style={{ overflowX: 'auto' }}>
                                    <Table aria-label="memorial-deceased-table" sx={{ borderCollapse: 'collapse' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={4} style={{ 
                                                    fontSize: "2rem", // Keep the same size as before
                                                    fontFamily: "Times New Roman",
                                                    color: "#ffffff", 
                                                    textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                    textAlign: 'center',
                                                }} 
                                                sx={{ borderBottom: 'none' }} 
                                                className="engraved-text">
                                                    {description}
                                                </TableCell>
                                            </TableRow>
                                            <br/>
                                            <TableRow>
                                                <TableCell 
                                                    style={{ 
                                                        color: "#ffffff", 
                                                        fontFamily: "Times New Roman", 
                                                        textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                        textAlign: 'center', 
                                                        fontSize: '1.5rem' 
                                                    }} 
                                                    sx={{ borderBottom: 'none' }} 
                                                    className="engraved-text">
                                                    Name
                                                </TableCell>
                                                <TableCell 
                                                    style={{ 
                                                        color: "#ffffff", 
                                                        fontFamily: "Times New Roman", 
                                                        textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                        textAlign: 'center', 
                                                        fontSize: '1.5rem' 
                                                    }} 
                                                    sx={{ borderBottom: 'none' }} 
                                                    className="engraved-text">
                                                    Surname
                                                </TableCell>
                                                <TableCell 
                                                    style={{ 
                                                        color: "#ffffff", 
                                                        fontFamily: "Times New Roman", 
                                                        textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                        textAlign: 'center', 
                                                        fontSize: '1.5rem' 
                                                    }} 
                                                    sx={{ borderBottom: 'none' }} 
                                                    className="engraved-text">
                                                    Date of Birth
                                                </TableCell>
                                                <TableCell 
                                                    style={{ 
                                                        color: "#ffffff", 
                                                        fontFamily: "Times New Roman", 
                                                        textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                        textAlign: 'center', 
                                                        fontSize: '1.5rem' 
                                                    }} 
                                                    sx={{ borderBottom: 'none' }} 
                                                    className="engraved-text">
                                                    Date of Death
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deceased && deceased.map((person, index) => (
                                                <TableRow key={index} style={{ textAlign: 'center' }}>
                                                    <TableCell 
                                                        style={{ 
                                                            color: "#ffffff", 
                                                            fontFamily: "Times New Roman", 
                                                            textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                            textAlign: 'center', 
                                                            fontSize: '1.5rem' 
                                                        }} 
                                                        sx={{ borderBottom: 'none' }} 
                                                        className="engraved-text">
                                                        {person.name}
                                                    </TableCell>
                                                    <TableCell 
                                                        style={{ 
                                                            color: "#ffffff", 
                                                            fontFamily: "Times New Roman", 
                                                            textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                            textAlign: 'center', 
                                                            fontSize: '1.5rem' 
                                                        }} 
                                                        sx={{ borderBottom: 'none' }} 
                                                        className="engraved-text">
                                                        {person.surname}
                                                    </TableCell>
                                                    <TableCell 
                                                        style={{ 
                                                            color: "#ffffff", 
                                                            fontFamily: "Times New Roman", 
                                                            textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                            textAlign: 'center', 
                                                            fontSize: '1.5rem' 
                                                        }} 
                                                        sx={{ borderBottom: 'none' }} 
                                                        className="engraved-text">
                                                        {dayjs(person.date_of_birth).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                    <TableCell 
                                                        style={{ 
                                                            color: "#ffffff", 
                                                            fontFamily: "Times New Roman", 
                                                            textShadow: "1px 1px 1px #888, -1px -1px 1px #000", 
                                                            textAlign: 'center', 
                                                            fontSize: '1.5rem' 
                                                        }} 
                                                        sx={{ borderBottom: 'none' }} 
                                                        className="engraved-text">
                                                        {dayjs(person.date_of_death).format('YYYY-MM-DD')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}

export default ViewMassMemorial;
